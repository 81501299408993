<template>
  <v-container>
    <br>

    <NavBar :step="2"></NavBar>

    <Titulo></Titulo>

    <br>

    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1"
            step="1">
            Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>

      <v-stepper-items>
        <v-stepper-content step="3">
          <div>

            <v-form ref="form" v-model="valid">
              <br>
              <br>
              <div class="formData">

                <h3 class="text-center labelPlanes">
                  Ingresa los datos de tu familia
                </h3>

                <h4 v-if="screen > 800" class="text-center labelSubPlanes">
                  Para registrar a tus hijos, debes agregar la información personal de tu grupo familiar.
                  <br> Es importante completarlo correctamente para no afectar la cobertura.
                </h4>

                <h4 v-else class="text-center labelSubPlanes2">
                  Para registrar a tus hijos, debes agregar la información personal de tu grupo familiar.
                  <br> Es importante completarlo correctamente para no afectar la cobertura.
                </h4>

                <br>

                <v-div fluid v-for="(benef, index) in beneficiaries" :key="index" :class="StyleContendor"
                  style="justify:center; align:center">

                  <label>Hijo {{ index + 1 }}</label>

                  <br>
                  <br>

                  <div class="d-flex  flex-md-row align-items-center">
                    <div class="mb-2 mb-md-0 mr-md-3" style="margin-right :6px; margin-bottom:15px">
                      <v-text-field :class="styleRut" v-model.lazy="benef.newRut" :rules="rutRules" maxlength="13"
                        label="RUT (sin punto y con guión)" required filled solo @input="changeRut(index)"
                        @change="addLocalStore()">
                      </v-text-field>
                    </div>
                    <div class="mb-2 mb-md-0 mr-md-3" :class="StyleSpaceBotom">
                      <!-- <v-select :class="styleParentesco" :items="personType" v-model="benef.tipo" label="Parentesco"
                        :rules="parentescoRules" return-object required filled solo @change="addLocalStore()">
                      </v-select> -->
                      <v-text-field :class="styleRut" v-model="benef.establishment" :rules="establishmentRules"
                        label="Establecimiento" required filled solo @change="addLocalStore()">
                      </v-text-field>
                    </div>
                    <!-- <div class="circle-container">
                      <button class="circle-button" @click.prevent="deletedBeneficiary(index)">
                        <v-icon style="color:white">mdi-minus</v-icon>
                      </button>
                    </div> -->
                  </div>

                  <br>

                  <div class="div-dates">
                    <div style="margin-right: 6px; margin-bottom:15px">
                      <v-text-field :class="StyleField" v-model="benef.name" :rules="nameRules" label="Nombre" required
                        maxlength="51" filled solo @change="addLocalStore()"></v-text-field>
                    </div>

                    <div style="margin-right: 6px; margin-bottom:15px">
                      <v-text-field :class="StyleField" v-model="benef.surname" :rules="surnameRules" label="Apellido"
                        maxlength="51" required filled solo @change="addLocalStore()"></v-text-field>
                    </div>

                    <div style="margin-bottom: auto;">
                      <v-text-field :class="StylePorcent" type="text" v-model="benef.porcentaje"
                        :rules="percentageRules" label="" maxlength="3" required filled solo
                        @keydown="validateNumberInput($event)" @change="addLocalStore()"></v-text-field>
                    </div>
                  </div>

                  <v-row>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="monthRules"
                        :items="months" v-model="benef.month" :loading="loadingMonths" v-on:change="getDays(index)"
                        item-text="name" label="Mes" return-object required filled solo
                        @change="addLocalStore('fecha', index)"></v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="dayRules"
                        :items="benef.days" v-model="benef.day" :loading="loadingDays"
                        :disabled="loadingDays || benef.month == null" item-text="name" label="Día" return-object
                        required filled solo @change="addLocalStore('fecha', index)">
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="yearRules"
                        value="2023" :items="years" v-model="benef.year" item-text="name" label="Año" return-object
                        required filled solo @change="addLocalStore('fecha', index)"></v-select>
                    </v-col>

                  </v-row>

                  <!-- <br>

                  <div class="d-flex  flex-md-row align-items-center">
                    <div class="mb-2 mb-md-0 mr-md-3" style="margin-right :6px; margin-bottom:15px">
                      <v-text-field :class="styleRut" v-model="benef.establishment" :rules="establishmentRules"
                        label="Establecimiento" required filled solo @change="addLocalStore()">
                      </v-text-field>
                    </div>
                  </div> -->

                  <br>

                  <br>
                  <br>
                  <br v-if="screen < 800">

                </v-div>

              </div>

              <br> <br v-if="screen > 800">


              <!-- <div class="formData">
                <v-row style=" justify: center ; align: center">

                  <v-btn class="botonAgregar" style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600; color: white;
                    border-radius: 8px; margin-top: -6%; " elevation="0" text @click="addBeneficiary()">
                    Agregar Otro
                  </v-btn>

                </v-row>

              </div>
              <br><br> -->

              <div :class="StyleAlert" style="max-width: 60%; margin: auto;">
                <div v-if="totalPorcentaje === 100">
                  <p class="messageAlertPositive">Porcentaje completado</p>
                </div>
                <div v-else>
                  <p class="messageAlertNegative">{{ totalPorcentaje }}% debe ajustar el porcentaje</p>
                </div>
                <br>
              </div>

              <br><br>

              <div class="button-container">
                <v-btn style="background-color: #2D387C; color: white; border-radius: 12px; width: 300px; height: 50px;"
                  @click="validate()">
                  Continuar
                </v-btn>

                <br>
                <br>

                <v-btn text @click="back()">
                  Atrás
                </v-btn>

              </div>
            </v-form>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import ShieldService from "../services/shield.service";
import RutUtils from "@/utils/rut.utils.js";
import Titulo from "./Titulo.vue"
import agelist from "@/services/age-list.service.js";
import hijoList from "@/services/hijo-list.service.js";
import swal from 'sweetalert';
import info1 from "@/assets/images/Ayuda.png"
import NavBar from "@/components/NavBar.vue";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
const moment = require('moment');

export default {
  name: "Step1",
  components: { Titulo, NavBar },
  data: () => ({
    months: [],
    days: ["Selecciona el mes"],
    yars: [],
    loadingDays: false,
    porcentajeTotal: 0,
    lengthBenef: null,
    firstPlanId: null,
    LoadBenef: false,
    info1,
    dialogFamiliar: false,
    indexInfo: '0',
    info: [['Isapre Individual', 'Isapre Familiar', 'Fonasa Individual', 'Fonasa Familiar'], ['100%', '100%', '100%', '100%'], ['30.000', '30.000', '30.000', '30.000'], ['50', '50', '50', '50'], ['0', '24', '0', '24'], ['0', '50', '0', '50']],
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    loadPlans: false,
    stepperColor: '#00968F',
    e1: 3,
    personType: [
      "Hijo(a)",
      "Pareja",
      "Cónyuge",
    ],
    styleParentesco: null,
    styleEdad: null,
    userData: {
      old: null,
      typePlan: null,
      couponId: null,
      name: null,
      surname: null,
      cantidadBenef: null,
    },
    beneficiaries: [],
    plan: [],
    old: null,
    screen: window.screen.width,
    agelist: [],
    hijoList: [],
    valid: false,
    establishmentRules: [
      (v) => !!v || "El establecimeinto es requerido",
    ],
    edadRules: [
      (v) => !!v || "La edad es requerida",
    ],
    parentescoRules: [
      (v) => !!v || "El parentesco es requerido",
    ],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    percentageRules: [
      (v) => /^\d+(\.\d{1,2})?$/.test(v) || 'Ingrese un porcentaje válido',
      (v) => parseFloat(v) > 0 || 'Ingrese un valor mayor que 0',
      (v) => parseFloat(v) >= 0 && parseFloat(v) <= 100 || '% entre 0 y 100'
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.lengthBenef = localStorage.getItem("cantBenef");
    this.userData.old = localStorage.getItem('old');
    this.firstPlanId = localStorage.getItem('firstPlanId');
    this.old = localStorage.getItem('old');
    this.userData.typePlan = localStorage.getItem("typePlan");
    this.userData.couponId = localStorage.getItem('couponId') == 'null' ? null : localStorage.getItem('couponId');

    this.getMonths();
    this.getYears();

    // if (this.lengthBenef == null || this.lengthBenef == 0) {
    //   this.addBeneficiary();
    // } else {
    //   this.getBeneficiaries();
    // }

    this.addBeneficiary();

    if (this.screen > 800) {
      this.styleParentesco = 'labelParentesco'
      this.styleRut = 'labelRut'
      this.StyleField = "labelField"
      this.StylePorcent = "labelPorcent"
      this.StyleAlert = "labelAlert"
      this.StyleContendor = "contenedorDatos"
      this.StyleSpaceBotom = "SpaceBotom"
    } else {
      this.styleParentesco = 'labelParentesco2'
      this.styleRut = 'labelRut2'
      this.StyleField = "labelField2"
      this.StylePorcent = "labelPorcent2"
      this.StyleAlert = "labelAlert2"
      this.StyleSpaceBotom = "SpaceBotom2"
    }

    this.getAgeList();
    this.getHijoList();
    this.getPlan();
  },
  methods: {
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async getDays(index) {
      if (this.beneficiaries[index].month.name) {
        this.loadingDays = true;
        this.beneficiaries[index].day = null;
        this.beneficiaries[index].days = await dateService.getDays(
          this.beneficiaries[index].month.name
        );
        this.loadingDays = false;
      }
    },
    changeRut(index) {
      const cleanRut = RutUtils.cleanRut(this.beneficiaries[index].newRut);
      const formattedRut = RutUtils.formatRut(this.beneficiaries[index].newRut);

      // Verificamos si el RUT ya existe en algún otro beneficiario.
      const isRutDuplicated = this.beneficiaries.some((beneficiary, idx) => {
        return idx !== index && RutUtils.cleanRut(beneficiary.rut) === cleanRut;
      });

      if (isRutDuplicated) {
        swal({
          title: "Atención!",
          text: "Tu RUT no puede coincidir con el de otro beneficiario!",
          icon: "info",
          button: "De acuerdo",
        });
      } else {
        this.beneficiaries[index].rut = formattedRut;
        this.beneficiaries[index].newRut = formattedRut;
        this.addLocalStore();
      }
    },
    getBeneficiaries() {
      var types = localStorage.getItem("benefTypes").split(';');

      var cont = 0;
      while (this.lengthBenef != cont) {
        var person = {
          tipo: types[cont],
          name: null,
          surname: null,
          rut: null,
          year: null,
          month: null,
          day: null,
          newRut: null,
          establishment: null,
        }
        this.beneficiaries.push(person);
        cont++;
      }
    },
    addLocalStore(type, index) {

      if (type == 'fecha' && this.beneficiaries[index].day && this.beneficiaries[index].month && this.beneficiaries[index].year) {
        var date = '' + this.beneficiaries[index].day.id + '/' + this.beneficiaries[index].month.number + '/' + this.beneficiaries[index].year.id;
        var fechaNacimiento = moment(date);
        var fechaActual = moment();
        var diffDates = fechaActual.diff(fechaNacimiento, 'years');

        if (diffDates < 0 || diffDates > 24) {
          this.beneficiaries[index].day = null;
          this.beneficiaries[index].month = null;
          this.beneficiaries[index].year = null;
          swal({
            title: "Atención!",
            text: "Sólo puedes incluir a un hijo que tenga entre 0 y 24 años.",
            icon: "info",
            button: "De acuerdo",
          });
        }

      }

      var edades = '';
      var parentescos = '';
      var newRut = '';
      var name = '';
      var surname = '';
      var porcentaje = '';
      var benefday = '';
      var benefmonth = '';
      var benefyear = '';
      var establishments = '';

      this.beneficiaries.forEach(benef => {
        edades = edades + benef.edad + ';';
        parentescos = parentescos + benef.tipo + ';';
        newRut = newRut + benef.newRut + ';';
        name = name + benef.name + ';';
        surname = surname + benef.surname + ';';
        porcentaje = porcentaje + benef.porcentaje + ';';
        establishments = benef.establishment + ';';

        benefday = benef.day ? benefday + benef.day.id + ';' : benefday;
        benefmonth = benef.month ? benefmonth + benef.month.number + ';' : benefmonth + null + ';';
        benefyear = benef.year ? benefyear + benef.year.id + ';' : benefyear;
      });
      localStorage.setItem("benefTypes", parentescos);
      localStorage.setItem("benefRut", newRut);
      localStorage.setItem("benefName", name);
      localStorage.setItem("benefSurname", surname);
      localStorage.setItem("benefPorcentaje", porcentaje);

      localStorage.setItem("benefday", benefday);
      localStorage.setItem("benefmonth", benefmonth);
      localStorage.setItem("benefyear", benefyear);
      localStorage.setItem("establishments", establishments);
    },
    addBeneficiary() {

      var contador;
      var i = 0;
      if (this.userData.typePlan == 'marsh-individual') {
        contador = 1;
      } else if (this.userData.typePlan == 'marsh-duo') {
        contador = 2;
      } else if (this.userData.typePlan == 'marsh-trio') {
        contador = 3;
      }

      while (i < contador) {
        var person = {
          tipo: null,
          edad: null,
          name: null,
          surname: null,
          rut: null,
          year: null,
          month: null,
          day: null,
          days: null,
          newRut: null,
          porcentaje: "0",

        }
        this.beneficiaries.push(person);
        i++;
      }

      // this.addLocalStore();
      localStorage.setItem("cantBenef", this.beneficiaries.length);

    },
    async deletedBeneficiary(index) {
      if ((this.beneficiaries.length - 1) > 0) {
        var cantidadBenef = 'Familiar'
      } else {
        this.LoadBenef = false
        cantidadBenef = 'Individual'
      }
      if (this.beneficiaries[index].edad && (this.beneficiaries[index].edad == this.old)) {
        localStorage.setItem('typePlan', cantidadBenef)
        if (this.beneficiaries) {
          var mayor = 0;
          this.beneficiaries.forEach((benef, indexBenef) => {
            if (benef.edad > this.old && indexBenef != index) {
              this.old = benef.edad
              mayor = 1;
            }
          });
          if (mayor != 0) {
            this.plan = await ShieldService.getPlans(this.userData.salud, cantidadBenef, this.old)
          } else {
            this.LoadBenef = false
            this.old = localStorage.getItem("old")
            this.plan = await ShieldService.getPlans(this.userData.salud, cantidadBenef, this.old)
          }
        } else {
          this.plan = await ShieldService.getPlans(this.userData.salud, cantidadBenef, this.old)
        }


      } else if (this.beneficiaries[index].edad == null && this.beneficiaries.length == 1) {
        this.LoadBenef = false
      }
      this.plan = await ShieldService.getPlans(this.userData.salud, cantidadBenef, this.old)
      this.beneficiaries.splice(index, 1)
      this.addLocalStore();
      localStorage.setItem("cantBenef", this.beneficiaries.length);
    },
    async validate() {
      if (this.totalPorcentaje === 100 && this.validateBeneficiaryPercentages()) {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcent';
        } else {
          this.StylePorcent = 'labelPorcent2';
        }
        if (this.$refs.form.validate()) {

          this.userData.cantidadBenef = this.beneficiaries.length;

          this.$router.push({ path: '/datos-contratante' });
        }
      } else {
        // Si el total de porcentajes no es igual a 100, marca los campos como no válidos
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcentRed';
        } else {
          this.StylePorcent = 'labelPorcent2Red';
        }
      }
    },
    validateBeneficiaryPercentages() {
      for (const benef of this.beneficiaries) {
        if (!benef.porcentaje || parseFloat(benef.porcentaje) === 0 || parseFloat(benef.porcentaje) % 1 !== 0) {
          return false;
        }
      }
      return true;
    },
    validateNumberInput(event) {
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) || // Números normales
        (event.keyCode >= 96 && event.keyCode <= 105) || // Números del teclado numérico
        event.keyCode === 8 || // Backspace
        event.keyCode === 46 || // Delete
        event.keyCode === 37 || // Flecha izquierda
        event.keyCode === 39 || // Flecha derecha
        event.keyCode === 9 // Tabulación
      ) {
        // Permite la acción predeterminada
      } else {
        event.preventDefault();
      }
    },
    async getAgeList() {
      this.loadingAgeList = true;
      this.agelist = await agelist.getAgeList();
      this.loadingAgeList = false;
    },
    async getHijoList() {
      this.loadingHijoList = true;
      this.hijoList = await hijoList.getHijoList();
      this.loadingHijoList = false;
    },
    async getPlan() {
      this.loadPlans = false
      this.plan = await ShieldService.getPlans(this.userData.salud, this.userData.typePlan, this.old, this.userData.couponId)
      this.loadPlans = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    back() {
      this.$router.push({ path: '/planes' })
    }
  },
  watch: {
    totalPorcentaje(newValue) {
      if (newValue === 100 && this.validateBeneficiaryPercentages()) {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcent';
        } else {
          this.StylePorcent = 'labelPorcent2';
        }
      } else {
        if (this.screen > 800) {
          this.StylePorcent = 'labelPorcentRed';
        } else {
          this.StylePorcent = 'labelPorcent2Red';
        }
      }
    }
  },
  computed: {
    totalPorcentaje() {
      let total = 0;
      for (const benef of this.beneficiaries) {
        if (benef.porcentaje) {
          const parsedPorcentaje = parseFloat(benef.porcentaje);
          if (!isNaN(parsedPorcentaje)) {
            total += parsedPorcentaje;
          }
        }
      }
      return total;
    }
  }
};
</script>

<style lang="scss">
.contenedorDatos {
  padding-left: 50px;
}

.SpaceBotom {
  margin-right: 18px;
  margin-bottom: 15px
}

.SpaceBotom2 {
  margin-right: 6px;
  margin-bottom: 15px
}

.messageAlertPositive {
  color: var(--AD-Primario-2, #4F74E3);
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 40px
}

.messageAlertNegative {
  color: #F25454;
  text-align: center;
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 40px
}

.labelPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
  padding: 1%;
  margin-bottom: 3%;
}

.labelSubPlanes {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding-bottom: 2%;
}

.labelSubPlanes2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;

}

.labelPorcent {
  width: 80px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  margin-bottom: auto;
}

.labelPorcent .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: 8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray;

}

.labelPorcentRed {
  width: 80px;
  height: 57px;
  background: #FFFFFF;
  border: 1px solid red;
  ;
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelPorcentRed .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: 8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray
}

.labelPorcent2 {
  width: 57px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelPorcent2 .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: -8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray
}

.labelPorcent2Red {
  width: 57px;
  height: 60px;
  background: #FFFFFF;
  border: 2px solid red;
  ;
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelPorcent2Red .v-text-field__slot::after {
  content: '%';
  position: absolute;
  right: -8px;
  /* Ajusta la posición del símbolo de porcentaje */
  top: 50%;
  transform: translateY(-50%);
  color: gray
}

.labelAlert {

  height: 48px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 5px;
  text-align: center;
}

.labelAlert2 {
  max-width: 75% !important;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 5px;
  text-align: center;
}

.labelField {
  width: 218px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelField2 {
  width: 113px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}


.labelParentesco {
  width: 260px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelParentesco2 {
  width: 145px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelEliminar {
  color: white;
  box-sizing: border-box;
  width: 35px;
  height: 35.1px;
  border: 0.5px solid #FFB600;
  margin-top: 15%
}

.labelRut {
  width: 260px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelRut2 {
  width: 145px;
  height: 57px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelsDatos {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.labelInfo {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #232323;
  opacity: 0.75;
  margin: auto;
}

.labelInfo3 {
  font-family: Helvetica;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  color: #232323;
  opacity: 0.75;
  margin: auto;
}

.botonAgregar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.step-date {
  max-width: 200px;
  align-items: center;
  display: flex;
  margin-left: 2rem;
  justify-content: center;

  .v-select {
    max-width: 400px;
  }
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 800px;
}

.infoIcon {
  background-image: url("~@/assets/images/Ayuda.png");
}

.labelResumen {
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  height: 114px;
  background: #0077A0;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: auto;
}

.labelNamePlan {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #FFFFFF;
}

.labelPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}

.labelSubDetalle {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #FFFFFF;
}

.labelSubPrecio {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: rgba(255, 255, 255, 0.87);
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.v-messages__message {
  padding-bottom: 10%;
  text-align: left;
  min-width: 100px;
}

.circle-container {
  // Boton de eliminar - circulo exterior
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.1px solid #00968F;
  margin-top: 10px;
}

.circle-button {
  // Boton de eliminar - circulo Helveticaior
  width: 29px;
  height: 29px;
  border-radius: 200px;
  background-color: var(--AD-Secundario-2, #EDB321);
  ;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: auto;
}

.circle-button v-icon {
  // Boton de eliminar - Medidas del minus
  width: 7px;
  height: 14px;
}

@media only screen and (max-width: 959.98px) {

  // Evita el mecanismo de esconder las letras del steper
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: block !important;
  }
}

@media only screen and (max-width: 700.98px) {
  .v-stepper__content {
    padding: 0px 0px 16px 0px !important;
  }
}

.primary-override .v-application .primary {
  // Aqui sobreescribe el color del primary
  background-color: #FFB600 !important;
  border-color: #FFB600 !important;
}

.v-stepper__step__step {
  // Este es el step y sus medidas
  height: 61px;
  width: 61px;
  font-size: 24px;
  margin-top: 25%;
  background-size: cover;
  background-image: url("~@/assets/images/step2.png");
}

@media only screen and (max-width: 767px) {
  .v-stepper__step__step {
    width: 42px;
    height: 42px;
    font-size: 20px;
    margin-top: 40%
  }
}

@media only screen and (min-width: 767px) {
  .v-stepper__header .v-divider {
    align-self: center;
    margin: 0 -35px;
  }
}

.v-stepper__header {
  // Stepper header - contenedor de steper completo
  box-shadow: none;
  max-width: 60%;
  align-items: center;
  margin: auto;
}

.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  // boton apagado
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #8E8E8E;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.v-stepper__label {
  padding: 10%;
}

@media only screen and (max-width: 767px) {
  .v-stepper__header {
    // Stepper header - contenedor de steper completo
    box-shadow: none;
    max-width: 100%;
    align-items: center;
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .v-stepper__step {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 15px; // el ancho
    position: relative;
  }
}



html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper div form.v-form div div.row div.col {
  flex-basis: 0;
  flex-grow: 0;
  max-width: 100%;
}

.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.labelDatesdesktop {
  background: #ffffff;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
  min-height: 57px;
  width: 160px;
  margin-top: 12px !important;

}

@media only screen and (max-width: 800.98px) {
  .labelDatesdesktop {
    background: #ffffff;
    border: 1.5px solid rgba(142, 142, 142, 0.2);
    box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
    border-radius: 6px;
    width: 93.5px;
    margin-top: 0px !important;
    margin-right: -17px !important;
  }
}

.div-dates {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 800.98px) {
  .div-dates {

    margin-bottom: 19px;
  }
}

.encabezado {
  color: #002C77;
  font-size: 13px;
  font-weight: 600;
  line-height: 15px;
  align-items: center;
  display: flex;
}

@media (min-width: 960px) {
  .v-application .mr-md-3 {
    margin-right: 6px !important;
  }
}
</style>