<template>
  <v-container style="margin:auto">

    <NavBar :step="1"></NavBar>

    <Titulo></Titulo>

    <br v-if="screen <= 800">
    <br v-if="screen <= 800">
    <br v-if="screen <= 800">

    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>
      <br>


      <v-stepper-items>
        <v-stepper-content step="2">

          <div class="formData">

            <v-form ref="form" v-model="valid" max-witdh="300px">

              <br>
              <br v-if="screen >= 800">

              <h3 class="text-center labelTituloo">Estos son nuestros planes</h3>

              <h4 class="text-center labelSubtitulo">Elige tu plan</h4>

              <br>

              <v-row>
                &nbsp;&nbsp;&nbsp;
                <v-text-field :class="labelDescuento" v-model="userData.couponId"
                  label="Ingresa aquí tu código de descuento" maxlength="51" :rules="couponRules" solo></v-text-field>

                <v-btn class="botonAplicar" style="background-color: var(--AD-Secundario-2, #EDB321);; color: white; width: 70px; height: 50px;"
                  solo @click="SendCouponID()">Aplicar
                </v-btn>

              </v-row>

            </v-form>

          </div>

          <br>
          <br>
          <br>

          <div v-if="screen > 800" class="planContainer">
            <PlanEscritorio v-for=" (plan, indice) in plans" :key="plan._id" :planData="plan" :index="indice"
              :userData="userData" :loadingPlans="loadingPlans" @next-step="validate" />
          </div>

          <div v-if="screen <= 800" class="planContainer">
            <PlanMovil :planData="plans" :userData="userData" :loadingPlans="loadingPlans"
              @next-step="validate" />
          </div>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row style="justify-content: center; margin-top: 1rem">
      <v-btn text @click="back()"> Atras </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";
import ShieldService from "../services/shield.service";
import Titulo from "../components/Titulo.vue"
import NavBar from "@/components/NavBar.vue";
import swal from 'sweetalert';

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, Titulo, NavBar },
  data: () => ({
    plans: [],
    userData: {
      couponId: null,
      old: null,
      typePlan: null,
      PlanName: null,
    },
    stepperColor: '#00968F',
    e1: 2,
    loadingPlans: false,
    labelDescuento: '',
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    screen: window.screen.width,
  }),
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
    } else {
      this.labelDescuento = 'labelDescuento2'
    }

    this.userData.old = localStorage.getItem('old')

    this.getPlans();

  },
  methods: {
    async SendCouponID() {
      if (this.$refs.form.validate()) {

        var Plan750 = await ShieldService.getPlans(
          'UF750',
          this.userData.old,
          this.userData.couponId,
        );

        var Plan1000= await ShieldService.getPlans(
          'UF1000',
          this.userData.old,
          this.userData.couponId,
        );

        if (Plan750 != 0 && Plan1000.length != 0) {

          this.plans = [];
          this.plans.push(Plan750[0])
          this.plans.push(Plan1000[0])

          this.loadingPlans = false;
          this.$forceUpdate();
        } else {
          this.userData.couponId = null
          swal({
            title: "Código no válido",
            text: "Lamentablemente no existen planes asociados a este código de descuento. Intenta con otro código.",
            icon: "info",
            button: "Ok",
          });
        }
      }
    },
    back() {
      this.$router.push({ path: '/' })
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async getPlans() {
      this.loadingPlans = true;
      var planIndividual = await ShieldService.getPlans('marsh-individual', this.userData.old, this.userData.couponId);
      var planDuo = await ShieldService.getPlans('marsh-duo', this.userData.old, this.userData.couponId);
      var planTrio = await ShieldService.getPlans('marsh-trio', this.userData.old, this.userData.couponId);
      this.plans.push(planIndividual[0])
      this.plans.push(planDuo[0])
      this.plans.push(planTrio[0])
      // console.log(this.plans);
      this.loadingPlans = false;
    },
  },
};
</script>


<style lang="scss">
.labelTituloo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(0, 44, 119, 1);
}

.labelSubtitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.botonAplicar {
  border-radius: 0px 10px 10px 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143.34%;
  text-transform: capitalize;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 72%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.planContainer {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>