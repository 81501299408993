<template>
  <header>
    <div style="max-width: 95%; margin:auto; margin-top: 1%">

      <div :class="[isMobile ? 'mobile-header' : 'desktop-header']">

        <div v-if="isMobile" class="mobile-logo-container">

          <router-link v-if="step != 0" :to="path">

            <v-icon
              style=" width: 7px; height: 14px; position: absolute; left:10%; color: #2D387C; padding-bottom: 5%;">
              mdi-chevron-left
            </v-icon>

          </router-link>

          <router-link v-else :to="path">

            <v-icon @click="sitio()"
              style=" width: 7px; height: 14px; position: absolute; left:10%; color: rgba(35, 35, 35, 1);">
              mdi-chevron-left
            </v-icon>

          </router-link>

          <!-- <router-link :to="{ path: '/' }"> -->
            <img :src="logo" alt="logo" class="mobile-logo" >
          <!-- </router-link> -->

        </div>

        <div v-else class="desktop-logo-container" style=" padding-left: 3%;">

          <!-- <router-link :to="{ path: '/' }"> -->

            <img :src="logoNav" alt="Image 1" class="menu-nav"
              style="padding-top: 15px; float: right; padding-right: 39px; max-height: 50px; max-width: 50%;">

          <!-- </router-link> -->

          <div>

            <router-link :to="path">

              <div v-if="step == 0">

                <v-icon @click="sitio()"
                  style=" width: 20px; height: 20px; color: rgba(51, 51, 204, 1); font-size: 40px; padding-top: 29.5px;">
                  mdi-chevron-left
                </v-icon>

                <span @click="sitio()" class="textoH3" style="margin-left: 10px;">Volver atrás</span>


              </div>

              <div v-else>

                <v-icon
                  style=" width: 20px; height: 20px; color: rgba(51, 51, 204, 1); font-size: 40px; padding-top: 29.5px;">
                  mdi-chevron-left
                </v-icon>

                <span class="textoH3" style="margin-left: 10px;">Volver atrás</span>

              </div>

            </router-link>

          </div>

        </div>

      </div>

    </div>

    <br>

  </header>
</template>
<script>
import logo from '@/assets/MercerMarsh.png';
import MenuNav from '@/assets/MenuNav.png';
import logoNav from '@/assets/MercerMarsh.png';
export default {
  name: 'MyHeader',
  props: ['step', "userData", "beneficiaries"], // Prop para pasar datos adicionales del botón de retroceso
  data() {
    return {
      displayOptions: false,
      smallScreen: false,
      logo,
      logoNav,
      MenuNav,
      isMobile: false,
      path: null,
      screen: window.screen.width,
    };
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 789;
    },
    sitio() {
      window.location.href = "https://www.sbseguros.cl/"
    }
  },
  mounted() {

    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    // console.log("navBar userData: ", this.userData)
    // console.log("navBar beneficiaries: ", this.beneficiaries)

    if (this.step == 0) {
      this.path = {
        path: ""
      }
    } else if (this.step == 1) {
      this.path = {
        path: "/"
      }
    } else if (this.step == 2) {
      this.path = {
        path: "/planes"
      }
    } else if (this.step == 3) {
      this.path = {
        path: "/beneficiarios"
      }
    } else if (this.step == 6) {
      this.path = {
        path: "/datos-contratante"
      }
    } else if(this.step == 7) {
      this.path = {
        path: "/"
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style lang="scss">
/* Estilos para el desktop */
.desktop-header {
  height: 65px;
  background: #FFFFFF;
  border: 1.6px solid rgba(0, 44, 119, 1);
  box-shadow: 0px 24px 30px rgba(0, 0, 0, 0.03);
  border-radius: 15px;

  .desktop-logo {
    width: 270px;
    height: 60px;
  }

  .desktop-images {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }
}

/* Estilos para mobile */
.mobile-header {
  .mobile-logo {
    width: 143px;
    height: 35px;
    display: flex;
    flex-direction: column;
    right: 0;
    justify-content: space-around;
  }
}

.mobile-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconStyle {
  width: 20px;
  height: 20px;
  color: rgba(51, 51, 204, 1);
  font-size: 40px;
  padding-top: 29.5px;
}

.textoH3 {
  position: absolute;
  width: 123px;
  height: 20px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  padding-top: 23px;
  /* primary */
  color: var(--AD-Primario-1, #2D387C);
}
</style>







