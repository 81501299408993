import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR, VUE_APP_PERK_KEY } = process.env;

export default class ShieldService {

  static async createStep(values, step, version, id) {
    try {
      var carroRes;
      var data = {};
      var lenght = 0;

      //Para el primer step del formulario
      if (step == 1) {
        //Para el primera interacción
        if (version == 1) {
          data = {
            name: values[0],
            email: values[1],
            edad: values[2],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            lenght: 1,
          };

          carroRes = await axios.post(
            `${VUE_APP_SHIELD_URL}/step/create`,
            params
          );
        }
        //Para las demás interacciones interacción
        else {
          values.forEach((element) => {
            if (element != null) {
              lenght = lenght + 1;
            }
          });
          data = {
            name: values[0],
            email: values[1],
            edad: values[2],
            salud: values[3],
          };
          const params = {
            sponsor: VUE_APP_SPONSOR,
            stepOne: data,
            id: id,
            lenght: lenght,
          };

          carroRes = await axios.put(
            `${VUE_APP_SHIELD_URL}/step/update`,
            params
          );
        }
      }
      //Para el segundo step del formulario
      else if (step == 2) {
        const params = {
          sponsor: VUE_APP_SPONSOR,
          steptwo: values,
          id: id,
          lenght: 5,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }
      //Para el tercer step del formulario

      else if (step == 4) {
        values.forEach((element) => {
          if (element != null) {
            lenght = lenght + 1;
          }
        });
        lenght = lenght + 1;
        data = {
          name: values[0],
          surname: values[1],
          serialNumber: values[2],
          rut: values[3],
          region: values[4],
          commune: values[5],
          address: values[6],
          email: values[7],
          phoneNumber: values[8],
        };
        const params = {
          sponsor: VUE_APP_SPONSOR,
          stepthree: data,
          id: id,
          lenght: lenght + 5,
        };

        carroRes = await axios.put(`${VUE_APP_SHIELD_URL}/step/update`, params);
      }

      return carroRes;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el Step",
      };
    }
  }

  static async getPlans(category, age, code) {

    var maxAge = age;

    try {
      var url = `${VUE_APP_SHIELD_URL}/accidente`
      var params = "";

      if (code) {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&age=${maxAge}&code=${code}`
      } else {
        params = url + `?sponsor=${VUE_APP_SPONSOR}&category=${category}&age=${maxAge}`
      }
      const shieldRes = await axios.get(
        params
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getPeople(personID) {

    try {

      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/person/get/${personID}`,
        {
          headers: { perk_key: VUE_APP_PERK_KEY },
        }
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los planes",
      };
    }
  }

  static async getContactActiveCampain(email) {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async createInsurance(person, beneficiaries, plan, stepId) {
    // console.log("aqui");
    // console.log(person);

    person.planName = person.PlanName
    person.birthdate = person.dates

    var BenefData = [];
    if (beneficiaries) {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          porcent: benef.porcent,
          fechaNacimiento: '' + benef.day + '-' + benef.month + '-' + benef.year,
        });
      });
      person.BenefDataAP = BenefData;
    }

    const jsonData = { person, planId: plan[0]._id, stepId: stepId };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/accidente/createWithRunWayPayment`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
    else throw shieldRes.data;
  }


  // static async createInsurance(person, beneficiaries, plan, stepId) {

  //   person.planName = person.PlanName
  //   person.birthdate = person.dates

  //   var BenefData = [];
  //   if (beneficiaries) {
  //     beneficiaries.forEach((benef) => {
  //       BenefData.push({
  //         name: benef.name,
  //         surname: benef.surname,
  //         rut: benef.rut,
  //         parentesco: benef.tipo,
  //         fechaNacimiento: '' + benef.day + '-' + benef.month + '-' + benef.year,
  //       });
  //     });
  //     person.BenefData = BenefData;
  //   }

  //   const jsonData = { person, planId: plan[0]._id, stepId: stepId };

  //   const shieldRes = await axios.post(
  //     `${VUE_APP_SHIELD_URL}/accidente`,
  //     jsonData
  //   );
  //   if (shieldRes.data.status === "ok") return shieldRes.data.data.link;
  //   else throw shieldRes.data;
  // }

  static async createInsuranceWithToku(person, beneficiaries, plan, stepId) {

    person.planName = person.PlanName
    person.birthdate = person.dates

    var BenefData = [];
    if (beneficiaries) {
      beneficiaries.forEach((benef) => {
        BenefData.push({
          name: benef.name,
          surname: benef.surname,
          rut: benef.rut,
          parentesco: benef.tipo,
          porcent: benef.porcent,
        });
      });
      person.BenefData = BenefData;
    }

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    const jsonData = { person, planId: plan[0]._id, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/accidente/createOnlyWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

}